<template>
    <div class="view-container">
        <app-bar :back="true" title="满编十二生肖">
        </app-bar>

        <div class="notice" v-show="isShowNotice">
            <span class="notic-content">{{ notice.tip }}</span>
        </div>

        <div class="full-zodiacs" v-if="!isLoading">
            <result-info-card v-for="fullZoidac in fullZodiacs" :key="fullZoidac.id" :date='fullZoidac.drawDate'
                :zodiac='getZodiacText(fullZoidac.drawZodiac)' :month='getMonthText(fullZoidac.drawMonth)'
                :time='getTimeText(fullZoidac.drawTime)' :show="needShow(fullZoidac)" type="3d"></result-info-card>
            <div class="tip-box">
                <span v-if="fullZodiacs.length == 0" class="tip">暂无数据</span>
            </div>
        </div>

        <div class="loading" v-else>
            <loading :is-full-page="false" :active="true" :opacity="0" :color="'#ff1744'" loader="dots">
            </loading>
        </div>
    </div>
</template>

<script>
import ResultInfoCard from '../components/result-info-card'
import AppBar from '@/components/app-bar'
import anime from 'animejs/lib/anime.es.js'

import api from "@/api/api"
import enums from "@/util/enum"

export default {
    components: {
        ResultInfoCard, AppBar
    },
    mounted() {
        this.noticeElement = document.querySelector(".notic-content")

        this.update()
        this.getNotice()
        
        setTimeout(() => {
            this.netUpdate()  
        }, 5000);

        //开启闪烁动画
        this.flash()
    },
    beforeDestroy() {
        clearTimeout(this.netTimeout)
        clearTimeout(this.flashTimmer)
        clearTimeout(this.noticeNetTimmer)
        if(this.noticeElement){
            anime.remove(this.noticeElement)
            this.noticeElement.remove()
        }
    },
    data() {
        return {
            fullZodiacs: [],
            isLoading: false,
            isError: false,
            netTimeout: undefined,
            flashTimmer: undefined,
            isShow: true,

            notice: {
                state: "0",
                tip: ""
            },
            noticeElement: undefined,
            isInNoticeAnimetion: false,
            noticeNetTimmer:null
        }
    },
    computed: {
        isShowNotice(){
            if(!this.notice){
                return false
            }
            if(this.notice.state == "0"){
                return false
            }

            if(this.notice.tip && this.notice.state == "1"){
                return true
            }

            return false
        }
    },
    methods: {
        getZodiacText: enums.getZodiacText,
        getMonthText: enums.getMonthText,
        getTimeText: enums.getTimeText,
        async update() {
            this.isLoading = true
            try {
                let resp = await api.getFullZodiacs()
                this.fullZodiacs = resp.data
                this.isLoading = false

            } catch (e) {
                this.netTimeout = setTimeout(() => {
                    this.update()
                }, 3000)
            }
        },
        needShow(zodiac) {
            if (zodiac.drawZodiac == -1) {
                return this.isShow
            }
            return true
        },
        async netUpdate() {
            //定时获取结果
            try {
                let resp = await api.getFullZodiacs()
                this.fullZodiacs = resp.data
                this.isLoading = false
                this.netTimeout = setTimeout(() => {
                    this.netUpdate()
                }, 5000)
            } catch (e) {
                this.netTimeout = setTimeout(() => {
                    this.netUpdate()
                }, 3000)
            }
        },
        flash() {
            this.flashTimmer = setTimeout(() => {
                this.isShow = false
                setTimeout(() => {
                    this.isShow = true
                }, 800);
                this.flash()
            }, 3000);
        },
        noticeAnime() {
            if (!this.isInNoticeAnimetion) {
                //让vue模板反应一会否则获取不到宽度
                this.$nextTick(() => {
                    this.isInNoticeAnimetion = true
                    anime.set(this.noticeElement, { translateX: 0 })
                    let noticeElementWidth = this.noticeElement.offsetWidth
                    anime({
                        targets: this.noticeElement,
                        translateX: (noticeElementWidth + 750) * -1,
                        duration: 10000,
                        easing: "linear",
                        complete: () => {
                            this.isInNoticeAnimetion = false
                            if (this.notice.state == "1") {
                                this.noticeAnime()
                            }
                        }
                    })
                })
            }
        },
        async getNotice(){
            //获取顶部通知信息
            try{
                let resp = await api.get3DNotice()
                this.notice = resp.data
                if(this.notice.state == '1'){
                    this.noticeAnime()
                }
                this.noticeNetTimmer = setTimeout(()=>{
                    this.getNotice()
                },8000)
            }catch(e){
                console.log(e)
                this.noticeNetTimmer = setTimeout(() => {
                    this.getNotice()
                }, 5000);
            }
            
        },
    }
}
</script>

<style scoped>
.full-zodiacs {
    text-align: center;
    /* background: red; */
}

.tip {
    font-size: 25px;
    font-family: xk;
}

.tip-box {
    margin-top: 100px;
    text-align: center;
}

.loading {
    height: 95vh;
}


.notice {
    font-family: sans-serif;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    background: rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.notic-content {
    position: absolute;
    left: 100%;
    display: inline-block;
}

.round-text {
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
}
</style>