export default{
    zodiacEnum:[
        {label:"鼠",value:0},
        {label:"牛",value:1},
        {label:"虎",value:2},
        {label:"兔",value:3},
        {label:"龙",value:4},
        {label:"蛇",value:5},
        {label:"马",value:6},
        {label:"羊",value:7},
        {label:"猴",value:8},
        {label:"鸡",value:9},
        {label:"狗",value:10},
        {label:"猪",value:11},
    ],
    monthEnum:[
        {label:"一月",value:0},
        {label:"二月",value:1},
        {label:"三月",value:2},
        {label:"四月",value:3},
        {label:"五月",value:4},
        {label:"六月",value:5},
        {label:"七月",value:6},
        {label:"八月",value:7},
        {label:"九月",value:8},
        {label:"十月",value:9},
        {label:"十一月",value:10},
        {label:"十二月",value:11},
    ],
    //子、丑、寅、卯、辰、巳、午、未、申、酉、戌、亥
    timeEnum:[
        {label:"子时",value:0},
        {label:"丑时",value:1},
        {label:"寅时",value:2},
        {label:"卯时",value:3},
        {label:"辰时",value:4},
        {label:"巳时",value:5},
        {label:"午时",value:6},
        {label:"未时",value:7},
        {label:"申时",value:8},
        {label:"酉时",value:9},
        {label:"戌时",value:10},
        {label:"亥时",value:11},
    ],
    getZodiacText(zodiac){
        switch(zodiac){
          case -1 : return "--"
          case 0 : return "鼠"
          case 1 : return "牛"
          case 2 : return "虎"
          case 3 : return "兔"
          case 4 : return "龙"
          case 5 : return "蛇"
          case 6 : return "马"
          case 7 : return "羊"
          case 8 : return "猴"
          case 9 : return "鸡"
          case 10 : return "狗"
          case 11 : return "猪"
          default:return"未知生肖"
        }
    },
    getTimeText(time){
        switch(time){
            case -1 : return "--"
            case 0 : return "子时"
            case 1 : return "丑时"
            case 2 : return "寅时"
            case 3 : return "卯时"
            case 4 : return "辰时"
            case 5 : return "巳时"
            case 6 : return "午时"
            case 7 : return "未时"
            case 8 : return "申时"
            case 9 : return "酉时"
            case 10 : return "戌时"
            case 11 : return "亥时"
            default:return"未知时辰"
        }
    },
    getMonthText(month){
        switch(month){
            case -1 : return "--"
            case 0 : return "一月"
            case 1 : return "二月"
            case 2 : return "三月"
            case 3 : return "四月"
            case 4 : return "五月"
            case 5 : return "六月"
            case 6 : return "七月"
            case 7 : return "八月"
            case 8 : return "九月"
            case 9 : return "十月"
            case 10 : return "十一月"
            case 11 : return "十二月"
            default:return"未知月份"
        }
    }

}