<template>
  <div class="app-bar">
    <div class="back">
        <router-link to="/" class="back" v-if="back">
            <img src="@/assets/back.svg"/>
        </router-link>
    </div>
    <slot name="title"><div class="title">{{title}}</div></slot>
    <div class="action">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props:["back","title"],
    data(){
        return {

        }
    }

}
</script>

<style>
    .app-bar{
        /* height: 100px; */
        /* background: rgba(255, 0, 0, 0.2); */
        padding: 14px;
        display: flex;
        align-items:center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .app-bar img{
        padding-top: 3px;
    }

    .title{
        font-weight: bold;
        font-size: 20px;
        float: left;
        text-align: left;
        padding-left: 15px;
        width: 100%;
        font-family: xk;
    }

    .action{

    }
</style>