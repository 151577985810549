<template>
  <div class="zodiac-box">
      <div class="zodiac-item" :class='[zodiacClass]'></div>
  </div>
</template>

<script>
export default {
    props:['zodiac'],
    computed:{
        zodiacClass(){
            switch(this.zodiac){
                case 'rat': return 'zodiac-rat'
                case 'ox' : return 'zodiac-ox'
                case 'tiger' : return 'zodiac-tiger'
                case 'hare' : return 'zodiac-hare'
                case 'dragon' : return 'zodiac-dragon'
                case 'snake' : return 'zodiac-snake'
                case 'horse' : return 'zodiac-horse'
                case 'sheep' : return 'zodiac-sheep'
                case 'monkey' : return 'zodiac-monkey'
                case 'rooster' : return 'zodiac-rooster'
                case 'dog' : return 'zodiac-dog'
                case 'boar' : return 'zodiac-boar'
            }
        }
    }
}
</script>

<style>
    .zodiac-box{
        width: 150px;
        height: 150px;
        background-color: rgba(255, 255, 255, 0.2);
        display: inline-block;
        backdrop-filter: blur(5px);
        box-shadow: 0px 13px 17px -4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin: 10px;
        box-sizing: border-box;
        border: 1px solid #fff;
    }

    .zodiac-item{
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .zodiac-rat{
        background-image: url('../assets/zodiac-rat.png');
    }

    .zodiac-ox{
        background-image: url('../assets/zodiac-ox.png');
    }
    .zodiac-tiger{
        background-image: url('../assets/zodiac-tiger.png');
    }
    .zodiac-hare{
        background-image: url('../assets/zodiac-hare.png');
    }
    .zodiac-dragon{
        background-image: url('../assets/zodiac-dragon.png');
    }
    .zodiac-snake{
        background-image: url('../assets/zodiac-snake.png');
    }
    .zodiac-horse{
        background-image: url('../assets/zodiac-horse.png');
    }
    .zodiac-sheep{
        background-image: url('../assets/zodiac-sheep.png');
    }
    .zodiac-monkey{
        background-image: url('../assets/zodiac-monkey.png');
    }
    .zodiac-rooster{
        background-image: url('../assets/zodiac-rooster.png');
    }
    .zodiac-dog{
        background-image: url('../assets/zodiac-dog.png');
    }
    .zodiac-boar{
        background-image: url('../assets/zodiac-boar.png');
    }

</style>