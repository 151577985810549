<template>
  <div class="date-picker">
        <my-datepicker class="inner-picker" :option="option" :date="date"></my-datepicker>
        <span class="search" @click="clickIcon">
          <img src="@/assets/search.svg">
        </span>
  </div>
</template>

<script>
import MyDatepicker from 'vue-datepicker'
import anime from 'animejs/lib/anime.es.js'
export default {
    props:[],
    emits:['onReset','onDatePicked'],
    components:{
        MyDatepicker
    },
    mounted(){

    },
    data(){
        return {
            option: {
                type: 'day',
                week: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                month: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                format: 'YYYY-MM-DD',
                placeholder: '按日期查找?',
                inputStyle: {
                    'display': 'inline-block',
                    'font-family':'sans-serif',
                    'opacity':'0',
                    'width' :'0',
                    'padding': '3px',
                    'line-height': '15px',
                    'font-size': '14px',
                    'border': '1px solid black',
                    'box-shadow': '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                    'border-radius': '5px',
                    'background':'rgba(255,255,255,0.1)',
                    'color': 'black'
                },
                color: {
                header: '#ccc',
                headerText: '#f00'
                },
                buttons: {
                ok: '确定',
                cancel: '取消'
                },
                overlayOpacity: 0.5, // 0.5 as default
                dismissible: true // as true as default
            },
            date:{
                time:""
            },
            showInput:false
        }
    },methods:{
        clickIcon(){
            if(!this.showInput){
                this.showInput = true
                this.date.time=""
                anime({
                    targets:'.inner-picker input',
                    width:85,
                    opacity:1,
                    duration:800
                })
            }else{
                this.showInput = false
                this.$emit('onReset')
                anime({
                    targets:'.inner-picker input',
                    width:0,
                    opacity:0,
                    duration:800
                })
            }

        }
    },
    watch:{
        date:{
            handler(){
                if(this.date.time){
                    this.$emit('onDatePicked',this.date.time)
                }
            },
            deep:true
        }
    }
}
</script>

<style>
    .date-picker{
        display: inline-flex;
        justify-content: space-around;
    }
    .date-picker .search{
        margin-left: 10px;
    }
</style>