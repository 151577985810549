<template>
  <div class="view-container">
      <app-bar :back="true" title="历史记录">
          <my-datepicker
            @onReset="onDateReset"
            @onDatePicked="onDatePickd"
          ></my-datepicker>
      </app-bar>

        <div v-if="!isLoading">
            <div class="day-group" v-for="zodiac in zodiacs" :key="zodiac.id">
                <div class="day-date">{{zodiac.drawDate}}</div>
                <result-info-card 
                    :zodiac='getZodiacText(zodiac.firstZodiac)' date='第一场 12:00'  :round="1" type="2d"
                    :month='getMonthText(zodiac.firstMonth)'></result-info-card>
                <result-info-card 
                    :zodiac='getZodiacText(zodiac.secondZodiac)' date='第二场 20:00'  :round="2" type="2d"
                    :month='getMonthText(zodiac.secondMonth)'></result-info-card>
            </div>

            <div class="tip-box">
                <span v-if="zodiacs.length == 0" class="tip">暂无数据</span>
            </div>
        </div>
        <div class="loading" v-else>
            <loading 
                :is-full-page="false"
                :active="true"
                :opacity="0"
                :color="'#ff1744'"
                loader="dots"
            >
            </loading>
        </div>
  </div>
</template>

<script>
import ResultInfoCard from '../components/result-info-card'
import AppBar from '@/components/app-bar'
import MyDatepicker from '@/components/my-datepicker'

import api from "@/api/api"
import enums from "@/util/enum"
export default {
    components:{
        ResultInfoCard,AppBar,MyDatepicker
    },
    mounted(){
        this.update()
    },
    beforeDestroy(){
        clearTimeout(this.netTimeout)
    },
    data(){
        return {
            zodiacs:[],
            isLoading:false,
            isError:false,
            netTimeout:undefined
        }
    },
    methods:{
        getZodiacText:enums.getZodiacText,
        getMonthText:enums.getMonthText,
        async update(){
            this.isLoading = true
            try{
                let resp = await api.getZodiacHistory()
                this.zodiacs = resp.data
                this.isLoading = false
            }catch(e){
                this.netTimeout = setTimeout(() => {
                    this.update()
                }, 5000);
            }
        },
        async onDatePickd(date){
            this.isLoading = true
            try{
                let resp = await api.getZodiacHistoryByDate(date)
                this.zodiacs = []
                if(resp.data){ 
                    this.zodiacs.push(resp.data)
                }
                this.isLoading = false
            }catch(e){

            }
        },
        onDateReset(){
            this.update()
        }
    }
}
</script>

<style scoped>

    .day-date{
        color: #797979;
        font-size: 20px;
        font-weight: blod;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: xk;
    }

    .tip{
        font-size: 25px;
        font-family: xk;
    }

    .tip-box{
        margin-top: 100px;
        text-align: center;
    }

    .loading{
        height: 95vh;
    }

    .search{
        padding-right: 15px;
    }
</style
</style>