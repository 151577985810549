<template>
  <div id="app">
    <transition  :name="$route.meta.routeMode">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import api from "@/api/api"
export default {
  mounted(){
    let agent = navigator.userAgent
    api.postCount(agent)
  }
}
</script>

<style>
  *{
      padding: 0;
      margin: 0;
  }

  @font-face {
      font-family: xk;
      src: url('./assets/source.eot');
      src:
      url('./assets/source.eot?#font-spider') format('embedded-opentype'),
      url('./assets/source.woff2') format('woff2'),
      url('./assets/source.woff') format('woff'),
      url('./assets/source.ttf') format('truetype'),
      url('./assets/source.svg') format('svg');
      font-weight: normal;
      font-style: normal;
  }


  body{
      height: 100%;
      background: #fff;
  }
  html{
      height: 100%;
  }

  @media screen and (min-width :500px) {
    #app{
      width: 500px;
      margin: 0 auto;
      position: relative;
      height: 100vh;
      background-position: 0 95px;
    }
  }

  @media screen and (max-width : 500px) {
    #app{
      width: 100vw;
      margin: 0 auto;
      height: 100vh;
      background-position: 0 50px;
    }
  }

  #app{
    background-image: url('./assets/bg13.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #fdfaf5;
    overflow: hidden;
    position: relative;
    
  }

  #app::-webkit-scrollbar{
    display: none;
  }

  /* -------------- 容器样式 ---------------------- */
    @media screen and (min-width :500px) {
        .view-container{
        width: 500px;
        height: 100vh;
        }
    }

    @media screen and (max-width : 500px) {
        .view-container{
        width: 100vw;
        height: 100vh;
        }
    }

    .view-container{
        background-image: url('./assets/bg13.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fdfaf5;

    }

    .view-container::-webkit-scrollbar{
        display: none;
    }

  #app::after{
    clear: both;
    content: "";
    width: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }

  /*----------------路由动画----------- */

.slide-right-enter-active,
.slide-left-enter-active,
.slide-right-leave-active,
.slide-left-leave-active {
    box-shadow: -20px 0 20px 0px rgba(0, 0, 0, 0.1);
    will-change: transform;
    transition: all 0.5s ease-out;
    position: absolute;
}
.slide-right-enter {
    opacity: 0;
    transform: translateX(-50%);
}
.slide-right-leave-to {
    z-index: 100;
    transform: translateX(102%);
}
.slide-right-leave {
    box-shadow: -20px 0 20px 0px rgba(0, 0, 0, 0.1);
}

.slide-left-enter {
    z-index: 100;
    transform: translateX(100%);
    box-shadow: -20px 0 20px 0px rgba(0, 0, 0, 0.1);
}
.slide-left-leave-to {
    opacity: 0.4;
    transform: translateX(-50%);
}



</style>
