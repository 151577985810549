<template>
    <div class="carousel">
        <div class="zodiac-container">
            <zodiac-card zodiac='monkey'></zodiac-card>
            <zodiac-card zodiac='rooster'></zodiac-card>
            <zodiac-card zodiac='dog'></zodiac-card>
            <zodiac-card zodiac='boar'></zodiac-card>
            <!-- buff -->
            <zodiac-card zodiac='rat' class="carousel-rat"></zodiac-card>
            <zodiac-card zodiac='ox' class="carousel-ox"></zodiac-card>
            <zodiac-card zodiac='tiger' class="carousel-tiger"></zodiac-card>
            <zodiac-card zodiac='hare' class="carousel-hare"></zodiac-card>
            <zodiac-card zodiac='dragon' class="carousel-dragon"></zodiac-card>
            <zodiac-card zodiac='snake' class="carousel-snake"></zodiac-card>
            <zodiac-card zodiac='horse' class="carousel-horse"></zodiac-card>
            <zodiac-card zodiac='sheep' class="carousel-sheep"></zodiac-card>
            <zodiac-card zodiac='monkey' class="carousel-monkey"></zodiac-card>
            <zodiac-card zodiac='rooster' class="carousel-rooster"></zodiac-card>
            <zodiac-card zodiac='dog' class="carousel-dog"></zodiac-card>
            <zodiac-card zodiac='boar' class="carousel-boar"></zodiac-card>
            <!-- buff -->
            <zodiac-card zodiac='rat' class="carousel-buffer"></zodiac-card>
            <zodiac-card zodiac='ox'></zodiac-card>
            <zodiac-card zodiac='tiger'></zodiac-card>
            <zodiac-card zodiac='hare'></zodiac-card>
        </div>
    </div>
</template>

<script>
import ZodiacCard from '../components/zodiac-card'
import anime from 'animejs/lib/anime.es.js'
export default {
    components:{
        ZodiacCard
    },
    mounted(){
        this.zodiacContainer = document.querySelector('.zodiac-container')
        this.zodiacBuffer = document.querySelector('.carousel-buffer')
        let zodiacs = this.zodiacs

        zodiacs.push(document.querySelector('.carousel-rat'))
        zodiacs.push(document.querySelector('.carousel-ox'))
        zodiacs.push(document.querySelector('.carousel-tiger'))
        zodiacs.push(document.querySelector('.carousel-hare'))
        zodiacs.push(document.querySelector('.carousel-dragon'))
        zodiacs.push(document.querySelector('.carousel-snake'))
        zodiacs.push(document.querySelector('.carousel-horse'))
        zodiacs.push(document.querySelector('.carousel-sheep'))
        zodiacs.push(document.querySelector('.carousel-monkey'))
        zodiacs.push(document.querySelector('.carousel-rooster'))
        zodiacs.push(document.querySelector('.carousel-dog'))
        zodiacs.push(document.querySelector('.carousel-boar'))

        this.resetTranslate()
        this.resetScale()

        // this.startSpin()
                
        // document.onvisibilitychange=()=>{
        //     if(document.visibilityState == 'visible'){
        //         console.log('setInterval')
        //         this.autoTimmer =  setInterval(() => { 
        //             this.next(1000,null,'easeInOutQuad')
        //         }, 3500);
        //     }else{
        //         console.log('clearInterval')
        //         clearInterval(this.autoTimmer)
        //     }
        // }

    },

    data(){
        return {
            //原点
            origin:-765,
            //当前坐标
            index : 0,
            //元素间隔
            atomcount:170,
            //十二生肖dom
            zodiacs:[],
            //十二生肖容器
            zodiacContainer:null,
            //最后一个容器缓存
            zodiacBuffer:null,
            //for debug
            //autoTimmer:null
            //是否在旋转动画
            isSpining:false,
            //当前转速
            spinSpeed:1000,
            //控制速度的计时器
            spinSpeedTimmer:null,
            //当前旋转动画曲线
            spinEasing:'easeOutSine',
            //停到指定位置
            spinStopUntilTo:0,
            //是否在动画中(防抖用)
            isInAnimettion:false,
            //旋转是否已经停止(外部很关心这个参数)
            isSpinCompleted:true,
            
            onZodiacSpinComplete:undefined,
        }
    },
    methods:{
        resetTranslate(){
            //重置容器位置
            anime.set(this.zodiacContainer,{translateX:this.origin})
        },
        resetScale(){
            //重置生肖卡缩放
            anime.set(this.zodiacBuffer,{scale:1})
            anime.set(this.zodiacs[0],{scale:1.2})
        },
        consistentScale(){
            //去除所有生肖卡缩放
            anime.set(this.zodiacBuffer,{scale:1})
            anime.set(this.zodiacs,{scale:1})
        },
        next(duration,onComplete,e){
            //组件外尽量别调用这个方法
            //在前一次next正在执行中再调用next动画将会出错

            //动画曲线
            let easing
            //缩放动画曲线
            let scaleEasing 
            if(e){
                easing = e
                scaleEasing = easing
            }else{
                easing = 'linear'
                scaleEasing = easing
            }
            
            //缩小动画
            anime({
                targets:this.zodiacs[this.index],
                scale:1,
                duration:duration,
                easing:scaleEasing
            })
            
            //指针后移
            this.index++

            if( this.index >=12){
                //当前指针是最后一个
                //恢复指针
                this.index =0
                //正常位移完成后恢复位移
                anime({
                    targets:this.zodiacContainer,
                    translateX: `-=${this.atomcount}`,
                    complete:()=>{
                        this.resetTranslate()
                    },
                    duration:duration,
                    easing:easing
                })
                //放大动画后恢复大小
                anime({
                    targets:this.zodiacBuffer,
                    scale:1.2,
                    duration:duration,
                    easing:scaleEasing,
                    complete:()=>{
                        this.resetScale() 
                        if(onComplete){
                            onComplete()
                        }
                    }
                })
            }else{
                //位移动画
                anime({
                    targets:this.zodiacContainer,
                    translateX: `-=${this.atomcount}`,
                    duration:duration,
                    easing:easing
                })
                //放大动画
                anime({
                    targets:this.zodiacs[this.index],
                    scale:1.2,
                    duration:duration,
                    easing:scaleEasing,
                    complete:()=>{
                        if(onComplete){
                            onComplete()
                        }
                    }
                })
            }

        },
        animeTo(to,duration){
            //动画到指定生肖
            if(!this.isInAnimettion){//防抖
                this.isInAnimettion = true
                let step = 0
                if(to >= this.index){
                    step = to - this.index
                }else{
                    step =to - this.index + 12
                }
                let d = duration/step
                
                let f = ()=>{
                    if(step > 0){
                        step--
                        this.next(d,f,'linear')
                    }else{
                        this.isInAnimettion=false
                    }
                }
                f()
            }

               
        },
        startSpin(){
            //开始快速旋转
            if(!this.isSpining){//防抖
                this.isSpining = true
                this.isSpinCompleted = false
                this.spinSpeedTimmer = setInterval(()=>{
                    //控制速度越来越快或越来越慢
                    if(this.isSpining){
                        if(this.spinSpeed > 180){
                            this.spinSpeed -= 10
                        }

                        if(this.spinSpeed < 500){
                            this.spinEasing = 'linear'
                        }
                    }else{
                        if(this.spinSpeed < 1000){
                            this.spinSpeed +=10
                        }
                        if(this.spinSpeed > 500){
                            this.spinEasing = 'linear'
                        }
                    }
                },100)

                let f = ()=>{
                    //暂停到指定生肖
                    if(this.spinSpeed > 800 && this.index == this.spinStopUntilTo && !this.isSpining){
                        clearInterval(this.spinSpeedTimmer)
                        this.isSpinCompleted = true
                        if(this.onZodiacSpinComplete){
                            this.onZodiacSpinComplete()
                        }
                    }else{
                        this.next(this.spinSpeed,f,this.spinEasing)
                    }
                }
                f()
            }

        },
        stopSpinUntil(index,onZodiacSpinComplete){
            //从快速旋转暂停到指定生肖
            this.onZodiacSpinComplete = onZodiacSpinComplete
            if(index >=0 && index <12){
                this.isSpining = false
                this.spinStopUntilTo = index
            }

        }
    }
}
</script>

<style scoped>
    .carousel{
        height: 220px;
        position: relative;
        /* border: 1px solid; */
        overflow: visible;
    }

    .zodiac-container{
        white-space: nowrap;
        position: absolute;
        top: 20px;
        /* top: 50%; */
        /* transform: translateY(-50%); */
        left: 50%;
    }
    .carousel button{
        position: absolute;
        bottom:0
    }

</style>