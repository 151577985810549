import request from '@/api/request'

export default{
    live(){
        return request.get("/api/live")
    },
    getFullZodiacs(){
        return request.get("/api/get-full-zodiacs")
    },
    getZodiacHistory(){
        return request.get("/api/zodiac-history")
    },
    getZodiacHistoryByDate(date){
        return request.get(`/api/zodiac-by-date?date=${date}`)
    },
    getNotice(){
        return request.get("/api/notice")
    },
    get3DNotice(){
        return request.get("/api/3d/notice")
    },
    postCount(userAgent){
        let data = new FormData()
        data.append('agent',userAgent)
        return request.post("/api/count",data)
    }
}