<template>
  <div class="month-carousel-container">
      <div class="month-indicator"></div>
        <div class="hidden">
            <div class="month-carousel">
                <div class="month-item">四&emsp;月</div>
                <div class="month-item">三&emsp;月</div>
                <div class="month-item">二&emsp;月</div>
                <div class="month-item">一&emsp;月</div>
                    <!-- buffer -->
                <div class="month-item">十二月</div>
                <div class="month-item">十一月</div>
                <div class="month-item">十&emsp;月</div>
                <div class="month-item">九&emsp;月</div>
                <div class="month-item">八&emsp;月</div>
                <div class="month-item">七&emsp;月</div>
                <div class="month-item">六&emsp;月</div>
                <div class="month-item">五&emsp;月</div>
                <div class="month-item">四&emsp;月</div>
                <div class="month-item">三&emsp;月</div>
                <div class="month-item">二&emsp;月</div>
                <div class="month-item">一&emsp;月</div>
                    <!-- buffer -->
                <div class="month-item">十二月</div>
                <div class="month-item">十一月</div>
                <div class="month-item">十&emsp;月</div>
                <div class="month-item">九&emsp;月</div>
            </div>
        </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
export default {
    mounted(){
        this.monthItems = document.querySelectorAll('.month-item')
        // console.log(this.monthItems)
        this.resetPosition()

        // this.startSpin()
    },
    data(){
        return {
            //dom元素
            monthItems:null,
            //实际索引
            index:0,
            //当前dom索引
            domIndex:15,
            //每个item位移大小
            atomcount:30,
            //是否在动画中(防抖用)
            isInAnimation:false,
            //是否在旋转动画
            isSpining:false,
            //当前转速
            spinSpeed:1000,
            //控制速度的计时器
            spinSpeedTimmer:null,
            //停到指定位置
            spinStopUntilTo:0,
            //旋转是否已经停止(外部很关心这个参数)
            isSpinCompleted:true,
            onMonthSpinComplete:undefined
        }
    },
    methods:{
        opacityAnimationFunction(el,i){
            let diff = Math.abs(this.domIndex - i )
            switch(diff){
                case 0 : return 1
                case 1 : return 0.75
                case 2 : return 0.5
                case 3 : return 0.25
                default : return 0
            }
            return 0
        },
        resetPosition(){
            anime.set(this.monthItems,{
                opacity:this.opacityAnimationFunction,
                translateY:0
             })
        },
        next(d,onComplete,e){
            this.index++
            this.domIndex--
            let easing = 'linear'
            let duration = 1000
            if(e){
                easing = e
            } 
            if(d){
                duration = d
            }

            if(this.index >= 12){
                anime({
                    targets:this.monthItems,
                    opacity:this.opacityAnimationFunction,
                    translateY:`+=${this.atomcount}`,
                    complete:()=>{
                        this.index = 0
                        this.domIndex = 15
                        this.resetPosition()
                        if(onComplete){
                           onComplete() 
                        }
                        // console.log("complete")
                    },
                    easing:easing,
                    duration:duration
                })
            }else{
                anime({
                    targets:this.monthItems,
                    opacity:this.opacityAnimationFunction,
                    translateY:`+=${this.atomcount}`,
                    easing:easing,
                    duration:duration,
                    complete:()=>{
                        if(onComplete){
                           onComplete() 
                        }
                    }
                })
            }

            
        },
        animeTo(to,duration){
            //动画到指定位置
            if(!this.isInAnimation){//防抖
                this.isInAnimation = true
                let step = 0
                if(to >= this.index){
                    step = to - this.index
                }else{
                    step =to - this.index + 12
                }
                let d = duration/step
                
                let f = ()=>{
                    if(step > 0){
                        step--
                        this.next(d,f)
                    }else{
                        this.isInAnimation=false
                    }
                }
                f()
            }
        },
        startSpin(){
            if(!this.isSpining){//防抖
                this.isSpining = true
                this.isSpinCompleted = false

                this.spinSpeedTimmer = setInterval(() => {
                    if(this.isSpining){
                        if(this.spinSpeed >= 100){
                            this.spinSpeed -= 10
                        }
                    }else{
                        if(this.spinSpeed < 1000){
                            this.spinSpeed += 10
                        }
                    }
                }, 100);

                let f = ()=>{
                    //暂停到指定月份
                    if(this.spinSpeed > 500 && this.index == this.spinStopUntilTo && !this.isSpining){
                        clearInterval(this.spinSpeedTimmer)
                        this.isSpinCompleted = true
                        if(this.onMonthSpinComplete){
                            this.onMonthSpinComplete()
                        }
                    }else{
                        this.next(this.spinSpeed,f)
                    }
                }
                f()
            }
        },
        stopSpinUntil(index,onMonthSpinComplete){
            this.onMonthSpinComplete = onMonthSpinComplete
            //从快速旋转暂停到指定生肖
            if(index >=0 && index <12){
                this.isSpining = false
                this.spinStopUntilTo = index
            }

        }
    }
}
</script>

<style scoped>
    .month-carousel-container{
        /* border: 1px solid red; */
        width: 100px;
        height: 210px;
        position: relative;
        /* overflow: hidden; */
    }
    .hidden{
        /* 解决会遮挡其他元素使其点击事件失效的坑爹问题 */
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .month-item{
        font-size: 16px;
        font-weight: bold;
        color: red;
        height: 20px;
        line-height: 20px;
        margin-top: 10px;
        white-space: pre;
        font-family: sans-serif;
    }

    .month-carousel{
        width: 100px;
        overflow: hidden;
        position: absolute;
        top:50%;
        transform: translateY(-465px);
        z-index: 50;
    }

    .month-indicator{
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid;
        box-sizing: content-box;
        position: absolute;
        width: 80px;
        height: 30px;
        top: 50%;
        left: -15px;
        transform: translateY(-10px);
        backdrop-filter: blur(6px);
        border-radius: 4px;
        z-index: 49;
    }

    /* for debug */
    .month-carousel-container button{
        position: absolute;
        bottom: 0;
        right: 0;
    }

</style>