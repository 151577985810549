<template>
  <div class="view-container">
      <app-bar :back="false" title="天选十二生肖">
          <div class="ation-box">
            <span class="help">  
                <a href="/help.html" 
                    target="_blank"
                >
                    <img src="@/assets/help.svg" alt="">
                </a>
            </span>

            <span class="download" v-if="!isWebView">  
                <a href="/download.html" 
                    target="_blank"
                >
                    <img src="@/assets/download.svg" alt="">
                </a>
            </span>
            <span class="history">  
                <router-link to='/history'><img src="@/assets/history.svg" alt=""></router-link>
            </span>
            <span class="full-zodiac">  
                <router-link to='/full'>3D</router-link>
            </span> 
          </div>
      </app-bar>
      <div class="notice" v-show="isShowNotice">
          <span class="notic-content">{{notice.tip}}</span>
      </div>

      <div class="toptip">
          {{topInfo}}
      </div>
      <div class="scenic">
          <month-carousel  class="scenic-month-carousel" ref="monthCarousel"></month-carousel>
          <zodiac-carousel ref="zodiacCarousel"></zodiac-carousel>
      </div>
      <div class="update-info">{{live.updateInfo}}</div>
      <div class="info-container">
          <result-info-card :zodiac='firstZodiac' date='第一场 12:00' 
            :month='firstMonth' :show="showFirst" :round="1" type="2d"
          >
          <!-- <template v-slot:date-text>
            <span class="round-text">第一场 </span>
            
            <span class="time-text"> 12:00</span>
            <span class="period-text">中午</span>

          </template> -->
        </result-info-card>
          <result-info-card :zodiac='secondZodiac' date='第二场 20:00' 
            :month='secondMonth' :show="showSecond" :round="2" type="2d"
            />
      </div>
  </div>
</template>

<script>
import ZodiacCarousel from '../components/zodiac-carousel'
import ResultInfoCard from '../components/result-info-card'
import MonthCarousel from '../components/month-carousel'
import AppBar from '@/components/app-bar'

import api from "@/api/api"
import enums from "@/util/enum"

import anime from 'animejs/lib/anime.es.js'

export default {
    components:{
        ZodiacCarousel,ResultInfoCard,MonthCarousel,AppBar
    },
    mounted(){
        let au = navigator.userAgent.toLowerCase()
        if(au.match(/wv/i) == 'wv'){
            this.isWebView = true
        }
        this.update()
        this.getNotice()
        this.noticeElement = document.querySelector(".notic-content")
        //监听浏览器切换后台
        // document.addEventListener("visibilitychange",this.onVisibilitychange)

        //开启闪烁动画
        this.flashAnime()
    },
    beforeDestroy(){
        clearTimeout(this.netTimmer)
        clearInterval(this.countdownTimmer)
        anime.remove(this.noticeElement)
        clearInterval(this.flashTimmer)
        clearTimeout(this.noticeNetTimmer)
        if(this.noticeElement){
            anime.remove(this.noticeElement)
            //fix:修复通知动画失效
            this.noticeElement.remove()
        }
        // document.removeEventListener("visibilitychange",this.onVisibilitychange)
    },
    data(){
        return {
            isWebView:false,
            topInfo:"--",
            notice:{
                state:"0",
                tip:""
            },
            noticeElement:undefined,
            isInNoticeAnimetion:false,
            showFirst:true,
            showSecond:true,
            live:{
                state: 0,
                distance:0,
                updateInfo:"--",
                indicator:0,
                nowZodiacIndex:0,
                nowMonthIndex:0,
                countdownTimmer:undefined,
                zodiac:{
                    id: 0,
                    drawDate: "0000-00-00",
                    firstZodiac: -1,
                    firstMonth: -1,
                    secondZodiac: -1,
                    secondMonth: -1
                },
                netTimmer:undefined,
                flashTimmer:undefined,
                noticeNetTimmer:undefined
            }
        }
    },
    computed:{
        firstZodiac(){
            return this.getZodiacText(this.live.zodiac.firstZodiac)
        },
        firstMonth(){
            return this.getMonthText(this.live.zodiac.firstMonth)
        },
        secondZodiac(){
            return this.getZodiacText(this.live.zodiac.secondZodiac)
        },
        secondMonth(){
            return this.getMonthText(this.live.zodiac.secondMonth)
        },
        isShowNotice(){
            if(!this.notice){
                return false
            }
            if(this.notice.state == "0"){
                return false
            }

            if(this.notice.tip && this.notice.state == "1"){
                return true
            }

            return false
        }
    },
    methods:{
        getZodiacText:enums.getZodiacText,
        getMonthText:enums.getMonthText,
        formatTime(time){
            if(time > -1){
                let hour = Math.floor(time/3600)
                let minute = Math.floor(time/60)%60
                let second = time % 60
                let result = hour+"时"
                if(minute < 10){
                    result += "0"+minute+"分"
                }else{
                    result += minute+"分"
                }
                if(second < 10){
                    result += "0"+second+"秒"
                }else{
                    result += second+"秒"
                }

                return result
                 
            }
            return ""
        },
        onVisibilitychange(event){
            //监听浏览器可见状态
            console.log("onVisibilitychange");
            if(event.visible){
                this.update();
            }
        },
        async update(){
            try{
                let resp = await api.live()
                let live = resp.data
                this.live.state = live.state
                this.live.distance = live.distance
                this.live.updateInfo = live.updateInfo
                this.live.nowZodiacIndex = live.nowZodiacIndex
                this.live.nowMonthIndex = live.nowMonthIndex
                this.live.indicator = live.indicator
                //每次请求只能触发一次以下状态
                //注意做好防抖
                if(this.live.state == 0){
                    this.topInfo = "天选结束"
                    //完成状态
                    if(this.$refs.zodiacCarousel.isSpinCompleted){
                        this.live = resp.data
                        this.$refs.zodiacCarousel.animeTo(this.live.nowZodiacIndex,1000)
                        this.$refs.monthCarousel.animeTo(this.live.nowMonthIndex,1000)
                    }else{
                        this.$refs.zodiacCarousel.stopSpinUntil(this.live.nowZodiacIndex,()=>{
                            this.live.zodiac.firstZodiac = live.zodiac.firstZodiac
                            this.live.zodiac.secondZodiac = live.zodiac.secondZodiac
                        })
                        this.$refs.monthCarousel.stopSpinUntil(this.live.nowMonthIndex,()=>{
                            this.live.zodiac.firstMonth = live.zodiac.firstMonth
                            this.live.zodiac.secondMonth = live.zodiac.secondMonth
                        })
                    }
                }else if(this.live.state == 2){
                    //待定状态
                    this.topInfo = "天选倒计时 " + this.formatTime(this.live.distance)

                    //倒计时
                    if(!this.countdownTimmer){
                        this.countdownTimmer = setInterval(() => {
                            this.live.distance -= 1
                            if(this.live.state == 2){
                                this.topInfo = "天选倒计时 " + this.formatTime(this.live.distance)
                            }  
                        }, 1000);
                    }

                    if(this.$refs.zodiacCarousel.isSpinCompleted){
                        this.live = resp.data
                        this.$refs.zodiacCarousel.animeTo(this.live.nowZodiacIndex,1000)
                        this.$refs.monthCarousel.animeTo(this.live.nowMonthIndex,1000)
                    }else{
                        //虽然不可能从天选时刻进入待定状态，但还是做了保险 -_-||
                        this.$refs.zodiacCarousel.stopSpinUntil(this.live.nowZodiacIndex,()=>{
                            this.live.zodiac.firstZodiac = live.zodiac.firstZodiac
                            this.live.zodiac.secondZodiac = live.zodiac.secondZodiac
                        })
                        this.$refs.monthCarousel.stopSpinUntil(this.live.nowMonthIndex,()=>{
                            this.live.zodiac.firstMonth = live.zodiac.firstMonth
                            this.live.zodiac.secondMonth = live.zodiac.secondMonth
                        })
                    }

                }else if(this.live.state == 1){
                    this.topInfo = "天选时刻"

                    this.live = resp.data
                    //天选时刻
                    this.$refs.zodiacCarousel.startSpin()
                    this.$refs.monthCarousel.startSpin()
                }

                this.netTimmer = setTimeout(() => {
                    this.update()
                }, 5000);

            }catch(e){
                console.log(e)
                 this.netTimmer = setTimeout(()=>{
                    this.update()
                },2500)
            }
        },
        async getNotice(){
            //获取顶部通知信息
            try{
                let resp = await api.getNotice()
                this.notice = resp.data
                if(this.notice.state == '1'){
                    this.noticeAnime()
                }
                this.noticeNetTimmer = setTimeout(()=>{
                    this.getNotice()
                },8000)
            }catch(e){
                console.log(e)
                this.noticeNetTimmer = setTimeout(() => {
                    this.getNotice()
                }, 5000);
            }
            
        },
        noticeAnime(){
            if(!this.isInNoticeAnimetion){
                //让vue模板反应一会否则获取不到宽度
                this.$nextTick(()=>{
                    this.isInNoticeAnimetion = true
                    anime.set(this.noticeElement,{translateX:0})
                    let noticeElementWidth = this.noticeElement.offsetWidth
                    anime({
                        targets:this.noticeElement,
                        translateX:(noticeElementWidth+750) * -1,
                        duration:10000,
                        easing:"linear",
                        complete:()=>{
                            this.isInNoticeAnimetion = false
                            if(this.notice.state == "1"){
                                this.noticeAnime()
                            }   
                        }
                    })
                })
            }
        },
        flashAnime(){
            this.flashTimmer = setTimeout(() => {
                //闪烁动画
                if(this.live.state == 2 || this.live.state == 1){
                    if(this.live.indicator == 1){
                        this.showFirst = false
                    }else if(this.live.indicator == 2){
                        this.showSecond = false
                    }
                    setTimeout(() => {
                        this.showFirst = true
                        this.showSecond = true
                    }, 800);
                }
                this.flashAnime()
            }, 3500);
        }
    }

}
</script>

<style scoped>
    .full-zodiac{
        font-weight: bold;
        font-size: 20px;
    }
    .full-zodiac a{
        color: black;
        text-decoration: none;
        font-family: sans-serif;
    }

    .ation-box{
        width: 150px;
        display: flex;
        justify-content: space-around;
    }
    
    .toptip{
        margin-top: 30px;
        text-align:center;
        font-size: 20px;
        font-weight: bold; 
        color: black;
        font-family: xk;
    }

    .info-container{
        margin-top: 40px;
    }

    .update-info{
        text-align: center;
        font-size: 15px;
        font-family: xk;
    }

    .scenic{
        /* border: 1px solid red; */
        margin-top: 50px;
        height: 220px;
        position: relative;
    }

    .scenic-month-carousel{
        position: absolute;
        right: 7%;
    }
    @media screen and (min-width :500px){
        .scenic-month-carousel{
            position: absolute;
            right: 14%;
            z-index: 100;
        }
    }
    .notice{
        font-family: sans-serif;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
        background: rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        overflow: hidden;
        position: relative;
    }
    .notic-content{
        position: absolute;
        left: 100%;
        display: inline-block;
    }

    .round-text{
        font-size: 18px;
        font-family: sans-serif;
        font-weight: bold;
    }

    .period-text{
        font-size: 12px;
        margin: 0 5px;
        font-family: sans-serif;
        font-weight: bold;
    }

    .time-text{
        font-size: 18px;
        font-family: sans-serif;
        font-weight: bold;
    }
</style>