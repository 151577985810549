import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import History from '../views/History.vue'
import Full from '../views/Full.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      routeMode:"slide-right"
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta:{
      routeMode:"slide-left"
    }
  },
  {
    path: '/full',
    name: 'Full',
    component: Full,
    meta:{
      routeMode:"slide-left"
    }
  },
  {
    path: '*',
    redirect:'/'
  },
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
