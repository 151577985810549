import axios from 'axios'

const baseUrl = process.env.VUE_APP_BASE_URL

const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    timeout: 10000 // request timeout
})

service.interceptors.response.use(
    respone=>{
        let resp = respone.data
        if(resp.code !== 2000){
            Promise.reject(new Error(resp.message || "未知错误"))
        }
        return resp
    },
    error=>{
        //for debug
        console.log(error)
        Promise.reject(error)
    }
)

export default service