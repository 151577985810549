<template>
  <div class="info-card">

    <div class="date-container">
        <!-- <div class="round-text" v-if="type=='2d' && round==1">第一场</div>
        <div class="round-text" v-if="type=='2d' && round==2">第二场</div> -->
        <!-- <div v-if="type=='3d'" style="width: 50px;"></div> -->
        
        <div> <slot name="date-text"><div class="date-info">{{date}}</div></slot></div>
        <!-- <div style="width: 50px;"></div> -->
    </div>

      <!-- <div class="date-info">{{date}}</div> -->

      <div class="content-container">
            <div class="zodiac-info" ref="zodiacInfo">
                {{zodiac}}
            </div>
          <div class="month-info" ref="monthInfo">
              {{month}}
          </div>
          <div class="time-info" ref="timeInfo">
              {{time}}
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        zodiac:String,
        date:String,
        month:String,
        time:String,
        type:String,
        round:Number,
        show:{
            type:Boolean,
            default:true
        }
    },
    mounted(){

    },data(){
        return {
        }
    },
    watch:{
        show:{
            handler(){
                this.$nextTick(()=>{
                    if(this.show){
                        this.$refs.zodiacInfo.style.opacity = 1
                        this.$refs.monthInfo.style.opacity = 1
                        this.$refs.timeInfo.style.opacity = 1
                    }else{
                        this.$refs.zodiacInfo.style.opacity = 0
                        this.$refs.monthInfo.style.opacity = 0
                        this.$refs.timeInfo.style.opacity = 0
                    }
                })
            },
            immediate:true
        }
    }


}
</script>

<style scoped>
    .info-card{
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(5px);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        padding: 5px;
        margin: 20px 15px;
        border-radius: 5px;
        border: 1px solid #fff;
        text-align: center;
        
    }

    .date-container{
        display :flex;
        justify-content:center;
        padding: 5px;
    }

    .date-info{
        font-size: 18px;
        font-family: sans-serif;
        font-weight: bold;
    }

    .round-text{
        font-size: 18px;
        font-family: sans-serif;
        font-weight: bold;
    }

    .content-container{
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        color: red;
        text-align: center;
        align-items: center;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    }

    .zodiac-info{
        font-size: 65px;
        font-family: xk;
        transition: opacity 0.3s;
        opacity: 1;
    }

    .month-info{
        font-size: 35px;
        font-family: xk;
        transition: opacity 0.3s;
        opacity: 1;
    }

    .time-info{
        font-size: 30px;
        font-family: xk;
        transition: opacity 0.3s;
        opacity: 1;
    }

</style>